* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Geometria-Medium';
}

@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria'), url('font/Geometria.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Italic'), url('font/Geometria-Italic.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Thin'), url('font/Geometria-Thin.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-ThinItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-ThinItalic'), url('font/Geometria-ThinItalic.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-ExtraLight'), url('font/Geometria-ExtraLight.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-ExtraLightItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-ExtraLightItalic'), url('font/Geometria-ExtraLightItalic.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Light'), url('font/Geometria-Light.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-LightItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-LightItalic'), url('font/Geometria-LightItalic.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Medium'), url('font/Geometria-Medium.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-MediumItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-MediumItalic'), url('font/Geometria-MediumItalic.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Bold'), url('font/Geometria-Bold.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-BoldItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-BoldItalic'), url('font/Geometria-BoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-ExtraBold'), url('font/Geometria-ExtraBold.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-ExtraBoldItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-ExtraBoldItalic'), url('font/Geometria-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Geometria-Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Heavy'), url('font/Geometria-Heavy.woff') format('woff');
}


@font-face {
  font-family: 'Geometria-HeavyItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-HeavyItalic'), url('font/Geometria-HeavyItalic.woff') format('woff');
}

.app {
  max-width: 600px;
  background: #242C3B;
  margin: auto;
  min-height: 100vh;
  overflow-y: auto;
}

.logo {
  width: fit-content;
  margin: auto;
}

.header {
  margin: 20px 24px;
  display: flex;
  position: relative;
}

.select_header {
  border: 1px solid #D98C4C;
  padding: 5px 10px;
  position: absolute;
  right: 0;
  top: 8px;
  font-size: 10px;
  color: white;
  border-radius: 10px;
}

.select_header div:first-child {
  color: #D98C4C;
}

.select_header div {
  display: flex;
  gap: 6px;
}

.form {
  padding: 26px 24px;
  background-color: white;
  border-radius: 30px 30px 0 0;
  min-height: calc(100vh - 80px);
}

.selected {
  color: black !important;
}

.selected option {
  color: #ABB0BC;
}

.input_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #ABB0BC;
  margin-bottom: 10px;
}

.input {
  height: 42px;
  position: relative;
  border-radius: 20px;
  padding: 0 10px;
  font-size: 22px;
  color: #ABB0BC;
  outline: none;
  border: 1px solid #ABB0BC;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('./../public/arrow-down.svg');
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  cursor: pointer;

}

.input_title {
  margin-left: 10px;
}

.center_text {
  text-align: center;
  color: black;
}

.no_wrap {
  white-space: nowrap;
}

.input::placeholder {
  color: rgba(171, 176, 188, 0.3);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 10px;
  opacity: 0; /* Начальное состояние - полностью прозрачные */
  transform: translateY(10px); /* Начинают смещенными вниз на 20 пикселей */
  animation: fadeInUp 0.7s ease-out forwards; /* Анимация применяется сразу при загрузке */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.buttons>div {
  width: 50%;
  height: 46px;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}

.button_img {
  width: 100%;
  height: 46px;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: white;
  animation: fadeInUp 0.7s ease-out forwards; 
  cursor: pointer;
  margin-bottom: 10px;
  background-color: rgba(76, 87, 112, 1);
  transition: all 0.3s ease-in-out
}

.button_accept {
  background-color: #D98C4C;
  transition: all 0.3s ease-in-out;
}

.button_object {
  background-color: rgba(76, 87, 112, 1);
  transition: all 0.3s ease-in-out; 
}

.button_object:hover,.button_accept:hover {
  border: 0.5px solid #ABB0BC;
}

.button_object:active,
.button_accept:active,
.button_img:active,
.finally_button:active {
  transform: scale(0.90);
}



.button {
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  max-width: 280px;
  color: white;
  margin: auto;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgba(171, 176, 188, 1);
  transition: all 0.5s;
}

.button:hover {
  background-color: rgba(255, 109, 0, 1);
}

.accept {
  border: 1px solid #5AC865;
  color: #5AC865 !important;
  background-color: rgba(90, 200, 101, 0.3) !important;
  position: relative;
}

.accept svg {
  width: 24px;
  height: 24px;
  position: absolute;
  right:10px;
}

.block_check {
  width: 100%;
  border: 1px solid #ABB0BC;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  padding: 10px 20px;
 
}



.country {
  font-family: "Geometria-Bold";
}

.block_check span {
  color: #D98C4C;
}

.amount {
  /*margin-top: 10px;*/
  font-family: "Geometria-Bold";
}

.amount b {
  color: #CE674B;
}

.country_block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}

.important {
  color: #D98C4C;
  font-size: 8px;
  width: 88%;
  text-align: end;
}

.key {
  font-family: 'Geometria-Light';
  font-size: 12px;
  white-space: nowrap;
}

.line_order {
  height: 0.5px;
  margin: 5px 0;
  background-color: #ABB0BC;
}

.value {
  font-size: 15px;
}

.info {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 5px;
}

.address {
  margin-bottom: 12px;
}

.address .value {
  font-size: 10px;
}

.info:last-child {
  margin-top: 12px;
  white-space: normal;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ABB0BC;
}



.ready_page {
  background-color: white;
  min-height: 100vh;
  padding: 40px 25px 20px 25px;
}

.donelogo {
  margin: auto;
  display: block;
  width: 223px;
}

.donetime {
  color: #CACACA;
  font-size: 10px;
  margin: 20px 0 10px 0;
}

.doneline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.done_sum {
  color:#FF9B00;
  font-size: 20px;
  font-weight: bold;
}

.done_prcie {
  color:#1E2324;
  font-size: 20px;
  font-weight: bold;
}

.done_divider {
  height: 1px;
  width: 100%;
  background-color: #FF9B00;
  margin: 15px 0 25px 0;
}

.done_orderinfo {
  display: flex;
  flex-direction: column;
  gap:20px;
}

.done_title {
  font-size: 17px;
  color: #1E2324;
  font-weight: 400;
}

.done_value {
  font-size: 17px;
  color: #1E2324;
  font-weight: 600;
}

.done_print {
  margin: 40px auto;
  display: block;
  width: 233px;
  transform: translateX(-20px);
}


.done_textblock {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap:10px;
}

.done_textblock_first {
  font-weight: 400;
  font-size: 14px;
  color:#1E2324;
}

.done_textblock_ru,.done_textblock_second {
  font-weight: 400;
  font-size: 14px;
  color:#BCBCBC;
}

.done_textblock_ru span {
  color: #FF9B00;
  font-size: 14px;
}

.button_ready {
  color: #CE674B;
  margin-top: 20px;
  cursor: pointer;
 
}

.finally_button {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.info_box {
  width: 100%;
  word-break:break-all;
}

.req_important {
  font-size: 9.5px;
  width: 100%;
  word-break: normal;
  text-align: start;
}

.black_text {
  color: black;
}

.loading_png {
  position: absolute;
  width: 60px;
  height: 60px;
  animation: zoomInOut 1s ease infinite;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.87);
  }
  100% {
    transform: scale(1); 
  }
}
.loading_svg {
  width: 300px;
  height: 300px;
}


.reqizites {
  width: 50%;  
}

.req_block {
  width: 100%;
  display: flex;
  gap:10px;
  align-items: center;
}

.button_qr{
  width: 50%;

}


.images {
  display: flex;
  flex-direction: column;
  gap:10px;
  margin-bottom: 10px;
}

.reqizites {
  color: black;
  background-color: rgba(171, 176, 188, 0.2);
  width: 50%;
  height: 46px;
  font-size: 15px;
  border-radius: 20px;
  border: none;
  padding: 0px 8px;
  outline: none;
}

.reqizites::placeholder {
  color: #ABB0BC;
}

.button_qr{
  color: #ABB0BC;
  background-color: rgba(171, 176, 188, 0.2);
  align-items: center;
  width: 50%;

  height: 46px;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
}

.images .button_check {
  color: #ABB0BC;
  background-color: rgba(171, 176, 188, 0.2);
  align-items: center;
  height: 46px;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  width: 100%;
}

.images .button_check div , .images .button_qr div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}


.active_false {
  background-color: lightgray;
}

.proggress_bar div {
  background-color: #D98C4C;
  height: 100%;
  position: absolute;
  left:0;
  bottom:0;
}

.proggress_bar {
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right:0;
}

.button_check {
  position: relative;
  overflow: hidden;
}

.button_qr {
  position: relative;
  overflow: hidden;
}

.with-keyboard {
  padding-bottom: 250px;
}